/* You can add global styles to this file, and also import other style files */
@import url(./reset.scss);
@import "../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";
@import 'ngx-toastr/toastr';


$base-color: #3F1A10;
$base-red:#DC281D;
$base-yellow:#FDB938;
$base-blue:#2E348C;
$stage_one:#C0BCB5;
$stage_two:#6699CC;
$stage_three:#FFF275;
$stage_four:#87F1FF;
$stage_five:#C0F5FA;
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
html {
  position: relative;
  min-width: 100%;
}

.window,
.test-window {
  position: relative;

}

body {
  margin: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", Arial, sans-serif;
  overflow: auto;
  overflow-x: hidden;
  background: transparent;
  font-size: 14px;
  line-height: 1.285;
}

h2 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", Arial, sans-serif;
  color: rgb(3, 54, 61);
  font-size: 22px;
  letter-spacing: 0px;
  line-height: 1.2;
  font-weight: 600;
  text-align: left;
}

h5 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;

  a {
    font-weight: 600;
    color: #2D5DE7;
  }
}

a {
  color: #2D5DE7;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently */
}

label {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", Arial, sans-serif;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 4px;
  margin-left: 5px;
}

input {
  width: 100%;
  border-radius: 4px;
  gap: 10px;
  outline: none;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  min-height: 32px;
  line-height: 1.28571;
  background-color: transparent;
  border: 1px solid rgb(216, 220, 222);
  box-shadow: none;
  box-sizing: border-box;
  padding: 0.428571em 0.857143em;
}

.select-box {
  position: relative;
  display: block;
  width: 100%;
  margin: 0 auto;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", Arial, sans-serif;
  font-size: 14px;
  color: #60666d;
}

@media (min-width: 768px) {
  .select-box {
    width: 70%;
  }
}

@media (min-width: 992px) {
  .select-box {
    width: 50%;
  }
}

@media (min-width: 1200px) {
  .select-box {
    width: 30%;
  }
}

.select-box__current {
  position: relative;
  box-shadow: 0 15px 30px -10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  outline: none;
}

.select-box__current:focus+.select-box__list {
  opacity: 1;
  -webkit-animation-name: none;
  animation-name: none;
}

.select-box__current:focus+.select-box__list .select-box__option {
  cursor: pointer;
}

.select-box__current:focus .select-box__icon {
  transform: translateY(-50%) rotate(180deg);
}

.select-box__icon {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  width: 20px;
  opacity: 0.3;
  transition: 0.2s ease;
}

.select-box__value {
  display: flex;
}

.select-box__input {
  display: none;
}

.select-box__input:checked+.select-box__input-text {
  display: block;
}

.select-box__input-text {
  display: none;
  width: 100%;
  margin: 0;
  padding: 15px;
  background-color: #fff;
}

.select-box__list {
  position: absolute;
  width: 100%;
  padding: 0;
  list-style: none;
  opacity: 0;
  -webkit-animation-name: HideList;
  animation-name: HideList;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: step-start;
  animation-timing-function: step-start;
  box-shadow: 0 15px 30px -10px rgba(0, 0, 0, 0.1);
}

.select-box__option {
  display: block;
  padding: 15px;
  background-color: #fff;
}

.select-box__option:hover,
.select-box__option:focus {
  color: #546c84;
  background-color: #fbfbfb;
}

.d-flex {
  margin-top: 10px;
}
button.naked{
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  text-align: center;
  text-transform: none;
  outline: none;
  transition: all .2s;
}
.btn {
  appearance: button;
  backface-visibility: hidden;
  background-color: #2D5DE7;
  border-radius: 6px;
  border-width: 0;
  box-shadow: rgba(50, 50, 93, .1) 0 0 0 1px inset, rgba(50, 50, 93, .1) 0 2px 5px 0, rgba(0, 0, 0, .07) 0 1px 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  height: 44px;
  margin: 12px 0 0;
  margin-top: 20px;
  outline: none;
  overflow: hidden;
  padding: 0px 25px;
  position: relative;
  text-align: center;
  text-transform: none;
  transform: translateZ(0);
  transition: all .2s, box-shadow .08s ease-in;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;

  &.goal {
    background-color: #59a263 !important;
  }
}

.btn:disabled {
  cursor: default;
}

.btn:focus {
  box-shadow: rgba(50, 50, 93, .1) 0 0 0 1px inset, rgba(50, 50, 93, .2) 0 6px 15px 0, rgba(0, 0, 0, .1) 0 2px 2px 0, rgba(50, 151, 211, .3) 0 0 0 4px;
}
  .center-text {
    text-align: center;
  }
  .uppercase {
    text-transform: uppercase;
  }
.tungsten {
  font-family: 'tungstenbold';
  font-size: 24px;
}
@font-face {
  font-family: 'tungstenbold';
  src: url('assets/fonts/tungsten-bold-webfont.woff2') format('woff2'),
       url('assets/fonts/tungsten-bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style:normal;

}
.insterstate {
  font-family: 'InterstateMono Regular';
  font-size: 24px;
}
@font-face {
  font-family: 'InterstateMono Regular';
  font-style: normal;
  font-weight: normal;
  src: local('InterstateMono Regular'), url('assets/fonts/InterstateMonoRegular.woff') format('woff');
  }

  .insterstate-light {
    font-family: 'InterstateMono Light';
  }
  @font-face {
  font-family: 'InterstateMono Light';
  font-style: normal;
  font-weight: normal;
  src: local('InterstateMono Light'), url('assets/fonts/InterstateMonoLight.woff') format('woff');
  }

  .insterstate-bold {
    font-family: 'InterstateMono Bold';
    font-weight: bold;
  }
  @font-face {
  font-family: 'InterstateMono Bold';
  font-style: normal;
  font-weight: bold;
  src: local('InterstateMono Bold'), url('assets/fonts/InterstateMonoBold.woff') format('woff');
  }

  .insterstate-black {
    font-family: 'InterstateMono Black';
  }
  @font-face {
  font-family: 'InterstateMono Black';
  font-style: normal;
  font-weight: normal;
  src: local('InterstateMono Black'), url('assets/fonts/InterstateMonoBlack.woff') format('woff');
  }


@-webkit-keyframes HideList {
  from {
    transform: scaleY(1);
  }

  to {
    transform: scaleY(0);
  }
}

@keyframes HideList {
  from {
    transform: scaleY(1);
  }

  to {
    transform: scaleY(0);
  }
}

//GRID SYSTEM
.container,
.container-fluid {
  max-width: 1600px;
  margin-right: auto;
  margin-left: auto
}

.container-fluid {
  padding-right: 2rem;
  padding-left: 2rem
}

.row {
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -.5rem;
  margin-left: -.5rem
}

.row.reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse
}

.col.reverse {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse
}

.col-xs,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-offset-0,
.col-xs-offset-1,
.col-xs-offset-10,
.col-xs-offset-11,
.col-xs-offset-12,
.col-xs-offset-2,
.col-xs-offset-3,
.col-xs-offset-4,
.col-xs-offset-5,
.col-xs-offset-6,
.col-xs-offset-7,
.col-xs-offset-8,
.col-xs-offset-9 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding-right: .5rem;
  padding-left: .5rem
}

.col-xs {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  max-width: 100%
}

.col-xs-1 {
  -ms-flex-preferred-size: 8.33333333%;
  flex-basis: 8.33333333%;
  max-width: 8.33333333%
}

.col-xs-2 {
  -ms-flex-preferred-size: 16.66666667%;
  flex-basis: 16.66666667%;
  max-width: 16.66666667%
}

.col-xs-3 {
  -ms-flex-preferred-size: 25%;
  flex-basis: 25%;
  max-width: 25%
}

.col-xs-4 {
  -ms-flex-preferred-size: 33.33333333%;
  flex-basis: 33.33333333%;
  max-width: 33.33333333%
}

.col-xs-5 {
  -ms-flex-preferred-size: 41.66666667%;
  flex-basis: 41.66666667%;
  max-width: 41.66666667%
}

.col-xs-6 {
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  max-width: 50%
}

.col-xs-7 {
  -ms-flex-preferred-size: 58.33333333%;
  flex-basis: 58.33333333%;
  max-width: 58.33333333%
}

.col-xs-8 {
  -ms-flex-preferred-size: 66.66666667%;
  flex-basis: 66.66666667%;
  max-width: 66.66666667%
}

.col-xs-9 {
  -ms-flex-preferred-size: 75%;
  flex-basis: 75%;
  max-width: 75%
}

.col-xs-10 {
  -ms-flex-preferred-size: 83.33333333%;
  flex-basis: 83.33333333%;
  max-width: 83.33333333%
}

.col-xs-11 {
  -ms-flex-preferred-size: 91.66666667%;
  flex-basis: 91.66666667%;
  max-width: 91.66666667%
}

.col-xs-12 {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  max-width: 100%
}

.col-xs-offset-0 {
  margin-left: 0
}

.col-xs-offset-1 {
  margin-left: 8.33333333%
}

.col-xs-offset-2 {
  margin-left: 16.66666667%
}

.col-xs-offset-3 {
  margin-left: 25%
}

.col-xs-offset-4 {
  margin-left: 33.33333333%
}

.col-xs-offset-5 {
  margin-left: 41.66666667%
}

.col-xs-offset-6 {
  margin-left: 50%
}

.col-xs-offset-7 {
  margin-left: 58.33333333%
}

.col-xs-offset-8 {
  margin-left: 66.66666667%
}

.col-xs-offset-9 {
  margin-left: 75%
}

.col-xs-offset-10 {
  margin-left: 83.33333333%
}

.col-xs-offset-11 {
  margin-left: 91.66666667%
}

.start-xs {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  text-align: start
}

.center-xs {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center
}

.end-xs {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  text-align: end
}

.top-xs {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start
}

.middle-xs {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.bottom-xs {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end
}

.around-xs {
  -ms-flex-pack: distribute;
  justify-content: space-around
}

.between-xs {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between
}

.first-xs {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1
}

.last-xs {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1
}

@media only screen and (min-width:48em) {
  

  .col-sm,
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-offset-0,
  .col-sm-offset-1,
  .col-sm-offset-10,
  .col-sm-offset-11,
  .col-sm-offset-12,
  .col-sm-offset-2,
  .col-sm-offset-3,
  .col-sm-offset-4,
  .col-sm-offset-5,
  .col-sm-offset-6,
  .col-sm-offset-7,
  .col-sm-offset-8,
  .col-sm-offset-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: .5rem;
    padding-left: .5rem
  }

  .col-sm {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%
  }

  .col-sm-1 {
    -ms-flex-preferred-size: 8.33333333%;
    flex-basis: 8.33333333%;
    max-width: 8.33333333%
  }

  .col-sm-2 {
    -ms-flex-preferred-size: 16.66666667%;
    flex-basis: 16.66666667%;
    max-width: 16.66666667%
  }

  .col-sm-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%
  }

  .col-sm-4 {
    -ms-flex-preferred-size: 33.33333333%;
    flex-basis: 33.33333333%;
    max-width: 33.33333333%
  }

  .col-sm-5 {
    -ms-flex-preferred-size: 41.66666667%;
    flex-basis: 41.66666667%;
    max-width: 41.66666667%
  }

  .col-sm-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%
  }

  .col-sm-7 {
    -ms-flex-preferred-size: 58.33333333%;
    flex-basis: 58.33333333%;
    max-width: 58.33333333%
  }

  .col-sm-8 {
    -ms-flex-preferred-size: 66.66666667%;
    flex-basis: 66.66666667%;
    max-width: 66.66666667%
  }

  .col-sm-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%
  }

  .col-sm-10 {
    -ms-flex-preferred-size: 83.33333333%;
    flex-basis: 83.33333333%;
    max-width: 83.33333333%
  }

  .col-sm-11 {
    -ms-flex-preferred-size: 91.66666667%;
    flex-basis: 91.66666667%;
    max-width: 91.66666667%
  }

  .col-sm-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%
  }

  .col-sm-offset-0 {
    margin-left: 0
  }

  .col-sm-offset-1 {
    margin-left: 8.33333333%
  }

  .col-sm-offset-2 {
    margin-left: 16.66666667%
  }

  .col-sm-offset-3 {
    margin-left: 25%
  }

  .col-sm-offset-4 {
    margin-left: 33.33333333%
  }

  .col-sm-offset-5 {
    margin-left: 41.66666667%
  }

  .col-sm-offset-6 {
    margin-left: 50%
  }

  .col-sm-offset-7 {
    margin-left: 58.33333333%
  }

  .col-sm-offset-8 {
    margin-left: 66.66666667%
  }

  .col-sm-offset-9 {
    margin-left: 75%
  }

  .col-sm-offset-10 {
    margin-left: 83.33333333%
  }

  .col-sm-offset-11 {
    margin-left: 91.66666667%
  }

  .start-sm {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    text-align: start
  }

  .center-sm {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center
  }

  .end-sm {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    text-align: end
  }

  .top-sm {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
  }

  .middle-sm {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
  }

  .bottom-sm {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end
  }

  .around-sm {
    -ms-flex-pack: distribute;
    justify-content: space-around
  }

  .between-sm {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
  }

  .first-sm {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1
  }

  .last-sm {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1
  }
}

@media only screen and (min-width:64em) {
  

  .col-md,
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-offset-0,
  .col-md-offset-1,
  .col-md-offset-10,
  .col-md-offset-11,
  .col-md-offset-12,
  .col-md-offset-2,
  .col-md-offset-3,
  .col-md-offset-4,
  .col-md-offset-5,
  .col-md-offset-6,
  .col-md-offset-7,
  .col-md-offset-8,
  .col-md-offset-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: .5rem;
    padding-left: .5rem
  }

  .col-md {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%
  }

  .col-md-1 {
    -ms-flex-preferred-size: 8.33333333%;
    flex-basis: 8.33333333%;
    max-width: 8.33333333%
  }

  .col-md-2 {
    -ms-flex-preferred-size: 16.66666667%;
    flex-basis: 16.66666667%;
    max-width: 16.66666667%
  }

  .col-md-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%
  }

  .col-md-4 {
    -ms-flex-preferred-size: 33.33333333%;
    flex-basis: 33.33333333%;
    max-width: 33.33333333%
  }

  .col-md-5 {
    -ms-flex-preferred-size: 41.66666667%;
    flex-basis: 41.66666667%;
    max-width: 41.66666667%
  }

  .col-md-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%
  }

  .col-md-7 {
    -ms-flex-preferred-size: 58.33333333%;
    flex-basis: 58.33333333%;
    max-width: 58.33333333%
  }

  .col-md-8 {
    -ms-flex-preferred-size: 66.66666667%;
    flex-basis: 66.66666667%;
    max-width: 66.66666667%
  }

  .col-md-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%
  }

  .col-md-10 {
    -ms-flex-preferred-size: 83.33333333%;
    flex-basis: 83.33333333%;
    max-width: 83.33333333%
  }

  .col-md-11 {
    -ms-flex-preferred-size: 91.66666667%;
    flex-basis: 91.66666667%;
    max-width: 91.66666667%
  }

  .col-md-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%
  }

  .col-md-offset-0 {
    margin-left: 0
  }

  .col-md-offset-1 {
    margin-left: 8.33333333%
  }

  .col-md-offset-2 {
    margin-left: 16.66666667%
  }

  .col-md-offset-3 {
    margin-left: 25%
  }

  .col-md-offset-4 {
    margin-left: 33.33333333%
  }

  .col-md-offset-5 {
    margin-left: 41.66666667%
  }

  .col-md-offset-6 {
    margin-left: 50%
  }

  .col-md-offset-7 {
    margin-left: 58.33333333%
  }

  .col-md-offset-8 {
    margin-left: 66.66666667%
  }

  .col-md-offset-9 {
    margin-left: 75%
  }

  .col-md-offset-10 {
    margin-left: 83.33333333%
  }

  .col-md-offset-11 {
    margin-left: 91.66666667%
  }

  .start-md {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    text-align: start
  }

  .center-md {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center
  }

  .end-md {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    text-align: end
  }

  .top-md {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
  }

  .middle-md {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
  }

  .bottom-md {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end
  }

  .around-md {
    -ms-flex-pack: distribute;
    justify-content: space-around
  }

  .between-md {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
  }

  .first-md {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1
  }

  .last-md {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1
  }
}

@media only screen and (min-width:75em) {
  

  .col-lg,
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-offset-0,
  .col-lg-offset-1,
  .col-lg-offset-10,
  .col-lg-offset-11,
  .col-lg-offset-12,
  .col-lg-offset-2,
  .col-lg-offset-3,
  .col-lg-offset-4,
  .col-lg-offset-5,
  .col-lg-offset-6,
  .col-lg-offset-7,
  .col-lg-offset-8,
  .col-lg-offset-9 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: .5rem;
    padding-left: .5rem
  }

  .col-lg {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%
  }

  .col-lg-1 {
    -ms-flex-preferred-size: 8.33333333%;
    flex-basis: 8.33333333%;
    max-width: 8.33333333%
  }

  .col-lg-2 {
    -ms-flex-preferred-size: 16.66666667%;
    flex-basis: 16.66666667%;
    max-width: 16.66666667%
  }

  .col-lg-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%
  }

  .col-lg-4 {
    -ms-flex-preferred-size: 33.33333333%;
    flex-basis: 33.33333333%;
    max-width: 33.33333333%
  }

  .col-lg-5 {
    -ms-flex-preferred-size: 41.66666667%;
    flex-basis: 41.66666667%;
    max-width: 41.66666667%
  }

  .col-lg-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%
  }

  .col-lg-7 {
    -ms-flex-preferred-size: 58.33333333%;
    flex-basis: 58.33333333%;
    max-width: 58.33333333%
  }

  .col-lg-8 {
    -ms-flex-preferred-size: 66.66666667%;
    flex-basis: 66.66666667%;
    max-width: 66.66666667%
  }

  .col-lg-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%
  }

  .col-lg-10 {
    -ms-flex-preferred-size: 83.33333333%;
    flex-basis: 83.33333333%;
    max-width: 83.33333333%
  }

  .col-lg-11 {
    -ms-flex-preferred-size: 91.66666667%;
    flex-basis: 91.66666667%;
    max-width: 91.66666667%
  }

  .col-lg-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%
  }

  .col-lg-offset-0 {
    margin-left: 0
  }

  .col-lg-offset-1 {
    margin-left: 8.33333333%
  }

  .col-lg-offset-2 {
    margin-left: 16.66666667%
  }

  .col-lg-offset-3 {
    margin-left: 25%
  }

  .col-lg-offset-4 {
    margin-left: 33.33333333%
  }

  .col-lg-offset-5 {
    margin-left: 41.66666667%
  }

  .col-lg-offset-6 {
    margin-left: 50%
  }

  .col-lg-offset-7 {
    margin-left: 58.33333333%
  }

  .col-lg-offset-8 {
    margin-left: 66.66666667%
  }

  .col-lg-offset-9 {
    margin-left: 75%
  }

  .col-lg-offset-10 {
    margin-left: 83.33333333%
  }

  .col-lg-offset-11 {
    margin-left: 91.66666667%
  }

  .start-lg {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    text-align: start
  }

  .center-lg {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center
  }

  .end-lg {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    text-align: end
  }

  .top-lg {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
  }

  .middle-lg {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
  }

  .bottom-lg {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end
  }

  .around-lg {
    -ms-flex-pack: distribute;
    justify-content: space-around
  }

  .between-lg {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
  }

  .first-lg {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1
  }

  .last-lg {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1
  }
}
mux-player{
  --seek-backward-button: none;
  --seek-forward-button: none;
}
    /**Desktop Query*/
    @media only screen and (min-width: 768px) {
      .desktop {
        display: block;
      }
      .mobile, .tablet {
        display: none;
      }
    }

    /*Tablet Query*/
    @media only screen and (min-width: 481px) and (max-width: 768px) {
      .tablet {
        display: block;
      }
      .mobile, .desktop {
        display: none;
      }
    }
    /*Mobile Query*/
    @media only screen and (max-width: 480px) {
      .mobile {
        display: block;
      }
      .desktop, .tablet {
        display: none;
      }
    }